<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo mt-0">
        <!-- <vuexy-logo /> -->
        <b-img
			v-if="($store.state.auth.configData !== '' && $store.state.auth.configData.name !== 'BeOnTrips' && !imageError)"
			:src="$store.state.auth.configData.clubLogo"
			alt="logo"
			width="180"
			@error="noImage"
		/>
		<Logo v-else/>
		<!-- <Logo /> -->

        <!-- <h2 class="brand-text text-primary ml-1">
          {{ name }}
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 title">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2 detail">
            Enter your email and we'll send you instructions to reset your
            password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group label="Email" label-for="forgot-password-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                class="resetButton"
                :disabled="loading ? disabled : !disabled"
                type="submit"
                variant="primary"
                block
              >
                Send reset link
                <div v-if="loading" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'login' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>

          <p style="<!-- width:max-content -->" class="clearfix mb-0">
            <span class="float-md-left d-block d-md-inline-block mt-2">
              COPYRIGHT © {{ new Date().getFullYear() }}
              <b-link class="ml-25">{{ name }}</b-link>,
              <span class="d-none d-sm-inline-block"> All Rights Reserved</span>
            </span>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
	BRow,
	BCol,
	BLink,
	BCardTitle,
	BCardText,
	BImg,
	BForm,
	BFormGroup,
	BFormInput,
	BButton
} from "bootstrap-vue";
// import { required, email } from "@validations";
import { required, email } from "vee-validate/dist/rules";

import store from "@/store/index";
import axios from "axios";
// import VuexyLogo from "@core/layouts/components/Logo.vue";
import Logo from "@/layouts/frontend-logo/Logo.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { name } from "@/helpers/constant";

extend("required", required);
extend("email", email);

export default {
	components: {
		Logo,
		BRow,
		BCol,
		BLink,
		BImg,
		BForm,
		BButton,
		BFormGroup,
		BFormInput,
		BCardTitle,
		BCardText,
		ValidationProvider,
		ValidationObserver
	},
	data() {
		return {
			form: {
				email: "",
				from: "admin"
			},
			disabled: true,

			sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
			// validation
			required,
			email,
			loading: false,
			name: (this.$store.state.auth?.configData && this.$store.state.auth?.configData?.name) ? this.$store.state.auth?.configData?.name : 'Beontrips',
			imageError: false
		};
	},
	computed: {
		imgUrl() {
			if (store.state.appConfig.layout.skin === "dark") {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
				return this.sideImg;
			}
			return this.sideImg;
		}
	},
	methods: {
		validationForm() {
			this.$refs.simpleRules.validate().then(success => {
				if (success) {
					this.loading = true;
					axios
						.post("auth/forgot-password", this.form)
						.then(res => {
							this.loading = false;
							this.$router.push("/Dashboard");
							this.$toast({
								component: ToastificationContent,
								props: {
									title: res.data.message,
									icon: "EditIcon",
									variant: "success"
								}
							});
						})
						.catch(error => {
							setTimeout(() => {
								this.loading = false;
							}, 3000);
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response
										? error.response.data.message
										: "Service is not available.Please try again later",
									icon: "X-CircleIcon",
									variant: "danger"
								}
							});
						});
				}
			});
		},
		noImage() {
			this.imageError = true
		}
	}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
